import { GatsbySeo } from 'gatsby-plugin-next-seo'
import RetireNaLoja from 'src/components/servicos/ServicesPage/RetireNaLoja/RetireNaLoja'

function Page() {
  return (
    <>
      <GatsbySeo language="pt-br" title="Retire na loja | Decathlon" />
      <RetireNaLoja />
    </>
  )
}

export default Page
